body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0,0,0,0);
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(255,255,255,.3);
}

.light::-webkit-scrollbar-thumb, .light .MuiPaper-root::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0,0,0,.2);
}

.light::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,.3);
}